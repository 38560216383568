.ms-TextField.is-disabled label,
.ms-TextField.is-disabled input,
.ms-TextField.is-disabled textarea,
.ms-Toggle.is-disabled,
.ms-TextField.is-disabled .ms-TextField-suffix,
.ms-Toggle.is-disabled label,
.ms-Dropdown.is-disabled span,
.ms-Label.ms-Dropdown-label
{
  color: #505050 !important;
}

.ms-CommandBar{
  border-bottom: 1px solid gray;
}
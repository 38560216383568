// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;
$border-radius:  3px;
$primary:#22388e;
// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
.badge{
    margin-right: 5px;
}
th, td{
  vertical-align: middle;  
}

